import { useReducer } from "react";
import { Themes } from "../types";
import themeData from '../data/themes.json';

const themeInitialState: ThemeState = {
   theme: themeData.themes[0]
}

export type ThemeState = {
   theme: any
}

export const useTheme = () => {
   const [state, dispatch] = useReducer(themeReducer, themeInitialState);

   const updateTheme = (value: Themes) => {
      const data = themeData.themes.find(t => t.name.toLowerCase() === value.toLowerCase());

      dispatch({
         type: ThemeActions.UPDATE_THEME,
         value: data
      });
   };

   const {
      theme
   } = state;

   const {
      name
   } = theme;

   return {
      theme,
      name,
      updateTheme
   }
};

export enum ThemeActions {
   UPDATE_THEME = 'UPDATE_THEME'
}

export const themeReducer = (
   state: ThemeState,
   {
      type,
      value
   }: {
      type: ThemeActions,
      value: any
   }
): ThemeState => {
   switch(type) {
      case ThemeActions.UPDATE_THEME:
         return {...state, theme: value}
      default:
         return state;
   }
}