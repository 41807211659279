import { createContext, useContext } from "react";
import { useTheme } from "../../../hooks";

type ThemeContextType = ReturnType<typeof useTheme>;

export const ThemeContext = createContext<ThemeContextType>(
   {} as unknown as ThemeContextType
);

export const ThemeState = ({children}: any): JSX.Element => {
   const appState = useTheme();

   return (
      <ThemeContext.Provider value={appState}>
         {children}
      </ThemeContext.Provider>
   )
};

export const useThemeState = () => useContext(ThemeContext);