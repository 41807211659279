import styled from "styled-components";

export const HeaderContainer = styled.div`
   width: 100%;
   height: auto;
   //background-color: ${props => props.theme.background.main5};
   display: flex;
   flex-direction: row;
   justify-content: center;
`;

export const HeaderTitle = styled.h1`
   color: ${props => props.theme.text.foreground1};
   padding: 16px;
   margin: 0;
`;