import { Header } from "../../components";
import { useThemeState } from "../../state";
import { HomeContainer } from "./Home.styled";


export const Home = () => {
   const {theme} = useThemeState();

   return (
      <HomeContainer theme={theme}>
         <Header/>
      </HomeContainer>
   );
}