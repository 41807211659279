import { useThemeState } from "../../state";
import { HeaderContainer, HeaderTitle } from "./Header.styled"


export const Header = () => {
   const {name, theme} = useThemeState();

   return (
      <HeaderContainer theme={theme}>
         <HeaderTitle theme={theme}>
            Postmen Studios
         </HeaderTitle>
      </HeaderContainer>
   );
}