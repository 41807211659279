import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Home, Error } from "../../views";


export const MainRouter = () => {
   return (
      <BrowserRouter>
         <Routes>
            <Route path="/home" element={<Home/>}/>

            <Route path="/" element={<Navigate to="/home"/>}/>
            <Route path="*" element={<Error/>}/>
         </Routes>
      </BrowserRouter>
   );
}